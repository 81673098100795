@keyframes buttonpulse {
  0% {
		border-width: 0.2rem;
		transform: scale(0.5);
		opacity: 1.0;
	}
    50% {
		opacity: 1.0;
	}
    100% {
		border-width: 0.2rem;
		transform: scale(1.4);
		opacity: 0.0;
	}
}

@keyframes buttonbreathe {
  0% {
		transform: scale(1);
  } 
  50% {
		transform: scale(1.1);
  }
  100% {
		transform: scale(1);
	}
}